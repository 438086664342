<template>
  <SchemaTable
      ref="schematableref"
      :schema=schema
      :model=dataModel
      :search=search
      :filters=filters
      :queryConf=queryConf
      :hasReset=hasReset
      :canCreate=canCreate
  >

  </SchemaTable>

</template>

<script>
import {SchemaTable, DataModel} from 'enn-schema-table'
import schema from './sn.schema.json'
import { uris, attachId } from "./../../api/uri";

export default {
  components: {
    SchemaTable
  },
  data() {
    return {
      search: "",
      filters: [ 'sn', 'orderId' ],
      schema,
      hasReset: true,
      canCreate: false,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.sn}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          
          item.orderName = item.order ? item.order.name : '';
          item.activated = item.activated === 1  ?true :false;
          if(item.order && item.order.product) item.productName = item.order.product.name;
          return item;
        },
        getApi: `${uris.sn}${attachId}`,
        getMap(res){
          res.orderName = res.order ? res.order.name : '';
          return res;
        },
        deleteApi: `${uris.sn}${attachId}`,
        // createApi: uris.factory,
        // updateApi: `${uris.factory}${attachId}`,
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {}
}
</script>

<style scoped>

</style>